::-webkit-scrollbar {
    display: none;
}

:root {
    --global-color: #ff8205;
}

.order-taking-prm-container {
    display: grid;
    grid-template-rows: auto minmax(312px, 357px) 1fr;
    grid-template-columns: auto 1fr;
    grid-template-areas:
        "prm-header prm-header prm-header "
        "prm-categories prm-products prm-products "
        "prm-footer prm-footer prm-footer ";
    height: 1920px;
}

.order-taking-prm-layout {
    width: 1080px;
    margin: 0 auto;
}

.order-taking-prm-header {
    grid-area: prm-header;
    height: 1466px !important;
}

nav#prm-categories {
    grid-area: prm-categories;
    margin-top: 2px !important;
    border-right: 1px solid #cccccc;
}

/* section#prm-products {
    grid-area: prm-products;
    display: flex;
    flex-direction: column;
    justify-content: center;
} */
section#prm-products {
    grid-area: prm-products;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr;
    align-items: center;
    padding-left: 1rem;
}

footer#prm-footer {
    grid-area: prm-footer;
    height: 97px;
    justify-content: center;

    /* position: fixed; */
    bottom: 0;
    width: 1080px;
    z-index: 1;
    background: #ffffff 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    gap: 10px;
}

.separation-line {
    height: 1px;
    width: 96%;
    background-color: #cccccc;
    margin: unset 2px unset 2px;
    margin-top: unset;
    margin-bottom: unset;
    border: 0px none;
}

.prm-category-title {
    display: flex;
    align-items: center;
    height: 100% !important;
    font: italic normal 600 40px/53px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    height: 70px;
    padding-left: 13px;
    background-color: white;
    z-index: 1;
}

.authenticated-user-buttons {
    height: 68px;
    width: 231px;
}

.prm-authenticated-user-font {
    display: flex;
    align-items: center;
    height: 100% !important;
    font: normal normal 25px/34px Nunito Sans;
    padding-left: 15px;
    gap: 5px;
    height: 70px;

    background-color: white;
    z-index: 1;
}
