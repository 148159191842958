.tags-swiper-width__clz {
    height: 45px !important;
    background: #33333326 0% 0% no-repeat padding-box;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content !important;
    padding: 0 10px !important;
}

.active-tags-swiper-width__clz {
    height: 45px !important;
    background: var(--global-color) 0% 0% no-repeat padding-box;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content !important;
    padding: 0 10px !important;
}

.tags-swiper__clz.swiper {
    /* width: 840px !important; */
    width: calc(calc(100vh - 230px) / 2);
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: unset !important;
    margin-right: unset !important;
    height: 70px;
}

.tags-swiper__clz .swiper-wrapper {
    align-items: center;
    width: calc(calc(100vh - 230px) / 2);
    gap: 15px;
}
