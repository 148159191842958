.category__clz {
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "center";
}

.category-card__clz {
    width: 166px;
    height: 157px;
    border-radius: 9px;
    box-shadow: 0px 3px 6px #00000029;
}

.category-swiper-slide-test__clz {
    height: 230px !important;
    /* width: 190px !important;
    margin-left: 10px;
    text-align: center;
    background-color: #e9e9e9;
    white-space: nowrap;
    color: #6a6a6a;
    display: flex;
    justify-content: center;
    align-items: center; */
}

.category-swiper__clz.swiper {
    width: 200px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* height: 1200px !important ; */
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.active-category-card__clz {
    width: 190px;
    height: 165px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 9px;
    border-bottom: var(--global-color) 5px solid;
    position: relative;
}

.active-category-card__clz::after {
    content: "";
    background: transparent
        linear-gradient(180deg, #ffffff00 50%, var(--global-color) 100%) 0% 0%
        no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 9px;
    border-bottom: var(--global-color) 5px solid;
    position: absolute;
    width: 190px;
    height: 165px;
    top: 0;
    left: 0;
    opacity: 0.3;
}

.category-title__clz {
    font: normal normal bold 16px/21px Segoe UI;
    text-align: center;
    width: 166px;
    letter-spacing: 0px;
    color: #343434;
    /* text-transform: uppercase; */
}

.active-category-title__clz {
    font: normal normal bold 16px/21px Segoe UI;
    text-align: center;
    width: 190px;
    letter-spacing: 0px;
    color: #343434;
    /* text-transform: uppercase; */
}

.category-swiper-slide__clz {
    height: 200px !important;
}

.category-swiper-slide__clz {
    transform: translateY(0);
    /* animation-name: fadeInSlideDown;
    animation-duration: 3s;  */
    /*  height: 200px !important;*/
}

@keyframes fadeInSlideDown {
    0% {
        opacity: 0.5;
    }
    /* 50% {
        opacity: 0.7;
    } 
    100% {
        opacity: 1;
    }*/
}

.category-swiper__clz {
    /* transform: translateY(0); */
    animation-name: fadeInDown;
    animation-duration: 2s;
}

@keyframes fadeInDown {
    0% {
        z-index: -10;
        transform: translateY(-55%);
    }
    20% {
        transform: translateY(-60%);
    }
    90% {
        transform: translateY(5%);
    }
    100% {
        z-index: 0;
        transform: translateY(0);
    }
}
