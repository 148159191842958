.header-additional-sale-dialog {
    height: 220px;
    width: 100%;
    gap: 20px;
}

.list-additional-products {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 1300px;
    width: 100%;
}

.additional-sale-button {
    width: 300px;
    height: 73px;
    border-radius: 5px;
}

.text-button {
    color: white;
    font: normal normal 600 27px/36px Segoe UI;
}

.text-header-additional-sale {
    font: normal normal normal 50px/60px Nunito;
}

.title-additional-sale {
    font: normal normal bold 72px/60px Nunito;
}

.column-centred-items-additional-sale {
    display: flex;
    flex-direction: var(--column-centred-items);
    justify-content: center;
    align-items: center;
}
