.usual-mode-logo__clz {
    height: 230px;
    width: 230px;
}

.prm-mode-logo__clz {
    z-index: 1;
    height: 190px;
    width: 190px;
}
