.authentication-button__clz {
    border-radius: 12px;
    background: #040406 !important;
}

.authentication-button__clz:hover {
    border: none !important;
    background: #040406 !important;
}

.hidden-qr-code__clz {
    font-size: 35px;
    border: unset;
    outline: none;
    color: white;
}

.hidden-qr-code__clz:focus {
    outline: none;
    caret-color: transparent;
}
