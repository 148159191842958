@keyframes zoom-in-zoom-out {
    65% {
        transform: scale(0.8, 0.8);
    }

    100% {
        transform: scale(1, 1);
    }
}
.card_Height {
    height: 442px !important;
    width: 380px !important;
}
.zoom-in-out-box {
    animation: zoom-in-zoom-out 0.15s ease;
}

.elem:hover {
    transform: scale(1.01);
}

/*  add product animation  */

:root {
    --top-position: 0;
    --left-position: 0;
    --top-basket-position: 0;
    --left-basket-position: 0;
    --column-centred-items: column;
}

.cloned-image {
    z-index: 1;
    transform-origin: center bottom;
    transform: scale(1);
}

.cloned-image-animation {
    animation-name: image-anim;
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

@keyframes image-anim {
    0% {
        top: --top-position;
        left: --left-position;
        opacity: 1;
        transform: scale(1);
        width: 245px;
        height: 215px;
    }
    50% {
        transform: scale(0.8);
    }
    70% {
        transform: scale(0.6);
    }
    100% {
        top: 1470px;
        transform: scale(0);
        left: var(--left-basket-position);
    }
}
/* prm-animation */

:root {
    --prm-top-position: 0;
    --prm-left-position: 0;
    --prm-top-basket-position: 0;
    --prm-left-basket-position: 0;
}

.prm-cloned-image {
    z-index: 1;
    transform-origin: center bottom;
    transform: scale(1);
}

.prm-cloned-image-animation {
    animation-name: prm-image-anim;
    animation-duration: 1.1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

@keyframes prm-image-anim {
    0% {
        top: --prm-top-position;
        left: --prm-left-position;
        opacity: 1;
        transform: scale(1);
        width: 245px;
        height: 215px;
    }
    50% {
        transform: scale(0.8);
    }
    70% {
        transform: scale(0.6);
    }
    100% {
        /* 1883* */
        top: 1810px;
        left: 763px;
        transform: scale(0);
        /* left: var(--prm-left-basket-position); */
    }
}

.centered-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img-Out-Of-Stock {
    background-size: unset;
    background-color: unset;
    position: absolute;
}

.first-box {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}

.second-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 205px;
    width: 100%;
}

.productContainer__clz {
    display: flex;
    flex-direction: column;
    border-radius: 11px;
    height: 420px;
    width: 363px;
}

.centredItems__clz {
    display: flex;
    justify-content: center;
    align-items: center;
}
.basic_compositionActive {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #34c38f;
    border-radius: 11px;
    opacity: 1;
}

.basic_compositionInactive {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #f60000;
    border-radius: 11px;
    opacity: 1;
}

.calorie {
    width: 100%;
    font: normal 400 12px/16px;
    font-family: Segoe UI;
    gap: 10px;
    min-height: 24px;
}
.price {
    font: normal bold 24px/29px Segoe UI;
    min-height: 35px;
}
.description__clz {
    text-align: justify;
    text-overflow: ellipsis;
    font-family: Segoe UI;
    font: normal 400 17px/20px Segoe UI;
    line-height: 1.5em;
    min-height: 85px;
}

.product-title__clz {
    font-size: 25px;
    font-family: Segoe UI;
    font: normal bold 25px/22px Segoe UI;
    line-height: 1.2em;
    min-height: 62px;
    gap: 5px;
    text-align: center;
}
.product-image {
    width: var(--width-product-img);
}
.button__clz {
    height: 100%;
    width: 70px;
    gap: 3px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content min-content;
}
.action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    height: 100%;
}

.scrollbarY {
    overflow-y: auto;
    scrollbar-width: none;
    scrollbar-color: gray #e0e0e0;
}
.scrollbarX {
    overflow-y: none;
    scrollbar-width: auto;
    scrollbar-color: gray #e0e0e0;
}

.column-centred-items {
    display: flex;
    /* flex-direction: var(--column-centred-items); */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
/* //! for next browser update dont delete*/
/* .row:has(> .item:nth-child(2)) {
    flex-direction: column;
    background-color: #f60000;
}
.row:has(> .item:nth-child(3)) {
    flex-direction: unset;
    background-color: #f60000;
} */
/*.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}*/

prm-product-container {
    display: flex;
    flex-direction: column;
    border-radius: 11px;
    height: 260px;
    width: 228px;
}
.prm-first-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 152px;
}
.prm-second-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 108px;
    width: 100%;
    gap: 2px;
}

.prm-product-image {
    height: 100%;
    width: 100%;
}

.prm-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 60px;
    gap: 5px;
    /* padding-top: 5px; */
    /* position: fixed; */
}
.prm-action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    /* height: 110px; */
}

.prm-calorie {
    width: 100%;
    font: normal normal 400 12px/16px Segoe UI;
    font-family: Segoe UI;
    gap: 10px;
    height: 16px;
}

.prm-price {
    font: normal normal bold 16px/21px Segoe UI;
    height: 20px;
}

.prm-description {
    /* width: 220px;
    height: 34px; */
    font: normal normal 400 12px/15px Segoe UI;
}

.prm-product-title {
    text-align: center;
    font: normal normal 600 15px/15px Segoe UI;
    color: #000000;
    line-height: 1.2em;
    height: 35px;
}

.prm-calorie-title {
    font: normal normal 400 12px/16px Segoe UI;
}

.prm-products-swiper-slide {
    width: 228px !important;
    /* padding: 50px 0px 50px 0px !important; */
}

.prm-products-swiper-container .swiper-wrapper {
    height: 264px !important;
    padding-left: 1rem !important;
    padding-top: 0.25rem !important;
    /* gap: 20px; */
    width: calc(calc(100vh - 230px) / 2);
    /* width: 860px !important; */
}
.prm-products-swiper-container.swiper {
    height: 300px !important;
    width: calc(calc(100vh - 230px) / 2);
    /* width: 840px !important; */
    margin-left: unset !important;
    /* align-items: center; */
    display: flex;
    width: 100%;
}

.prm-products-swiper-container > .swiper-pagination {
    /* width: 855px; */
    display: flex;
    justify-content: center;
}

.prm-products-swiper-container
    > .swiper-pagination
    > .swiper-pagination-bullet-active {
    opacity: 1 !important;
    background: #000 !important;
}

.pmr-grid {
    display: grid;
    grid-template-rows: 140px auto;
}
.card__preview_description {
    /* text-align: justify; */
    text-overflow: ellipsis;
    font-family: Segoe UI;
    font: normal 400 17px/20px Segoe UI;
    line-height: 1.5em;
    /* min-height: 85px; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}

.initialFlexDirection {
    flex-direction: unset !important;
}
.three-lines-preview-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.list-container {
    scrollbar-width: none;
    height: 99% !important;
}
