@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap");

.product-size-swiper-container.swiper {
    width: 80% !important;
    height: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    top: 30px;
}

.product-size-swiper-slide.swiper-slide {
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    width: 275px !important;
}

.product-size-swiper-slide-img.swiper-slide img {
    display: block;
    width: 100%;
    height: 80%;
}

.popup-size-text {
    text-align: center;
    font: normal normal normal 50px/60px Nunito;
    letter-spacing: 0px;
    color: #0d0c0c;
}

.popup-size-product-title {
    text-align: center;
    font: normal normal bold 72px/60px Nunito;
    letter-spacing: 0px;
    color: #0d0c0c;
}

.popup-size-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70%;
}

.popup-size-buttons {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.swiper-slide-product-img {
    width: 258px;
    height: 258px;
    background: #f6f8f6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 38px;
    display: flex;
    align-items: center;
}

.swiper-slide-product-name {
    height: 54px;
    text-align: center;
    font: normal normal bold 40px/54px Nunito;
    letter-spacing: 0px;
    color: #464646;
    text-transform: uppercase;
    width: max-content;
}

.swiper-slide-product-price {
    width: 83px;
    height: 41px;
    text-align: center;
    background: #f6f8f6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid var(--global-color);
    border-radius: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.next-button {
    width: 257px;
    height: 59px;
    background: transparent linear-gradient(180deg, #05fbf2 0%, #00a2bf 100%) 0%
        0% no-repeat padding-box;
    border: 1px solid #3100ff;
    border-radius: 38px;
}

.back-button {
    width: 228px;
    height: 59px;
    background: #ccfff9 0% 0% no-repeat padding-box;
    border: 1px solid #05fee1;
    border-radius: 38px;
}

.next-button-text {
    text-align: center;
    font: normal normal bold 30px/41px Nunito;
    letter-spacing: 0px;
    color: #ffffff;
}

.back-button-text {
    text-align: center;
    font: normal normal 600 27px/36px Segoe UI;
    letter-spacing: 0px;
    color: #6a92ff;
}

.price-text {
    height: 20px;
    text-align: center;
    font: normal normal bold 15px/20px Nunito;
}

.product-size-swiper-container-item-more-than-four.swiper {
    width: 100% !important;
    height: 100% !important;
    /*width: 469px !important; */
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    top: 30px;
    margin: unset;
}

.product-size-swiper-container-item-equal-two {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    top: 30px;
    gap: 20px;
}

.product-size-swiper-slide-item-equal-two {
    height: 400px;
    width: 275px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.product-size-swiper-slide-img-item-equal-two img {
    display: block;
    width: 100%;
    height: 80%;
}
