.allergen-card__clz {
    font: normal 20px/47px Segoe UI;
    width: 260px;
    height: 49px;
    /* boxShadow: "0px 3px 6px #00000029";
             borderRadius: "15px"; */
    color: #000000;
    display: flex;
    align-items: center;
    gap: 15px;
    padding-left: 15px;
    border-bottom: 1px solid #b8b8b8;
}

.alert-message__clz {
    gap: 20px;
    width: 1005px;
    height: 106px;
    background: #c1c1c1 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #a20505ba;
    position: relative;
    /* opacity: 0.07 */
}

.alert-message__clz::after {
    position: absolute;
    gap: 20px;
    width: 1005px;
    height: 106px;
    background: #c1c1c1 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #a20505ba;
    opacity: 0.07;
}

.product-composition-img {
    width: 145px;
    height: 154px;

    /* UI Properties */

    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #000000;
    border-radius: 6px;
}

.product-composition-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: wrap;

    gap: 15px;
}

.product-composition-item {
    width: 145px;
    height: 185px;
    text-align: center;
    font: normal normal 600 20px/27px Segoe UI;
    color: #0d0c0c;
}
.card-media {
    width: 40%;
}

.two-lines-preview-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
