.info-image-price__clz {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

  .slide-popup {
           width: max-content !important;
  }

  .arrow-left__clz{
      display: block;
      margin: 30px auto;
      width: 25px;
      height: 25px;
      color: black;
      border-top: 5px solid rgb(0, 0, 0);
      border-left: 5px solid rgb(0, 0, 0);
  }

  .arrow-left__clz {
      transform: rotate(-45deg);
  }

  .cart-image-product__clz {
      height: 70px;
      width: 93px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 10px;
     
  }
  .swiper-container__clz {
    width: 100% !important;
    height: 260px !important;
  }

  .swiper-slide-see-details{
      width: var(--slide-width) !important;
  
  }
  .product-details__clz{font:"normal normal normal 12px/16px Segoe UI";
    padding-left:3px
}

  .title-product-details {
      font: normal normal bold 20px/24px Segoe UI;
      letter-spacing: 0px;
      color: #000000;
      text-transform: uppercase;
      opacity: 1;
      text-align: left;
      margin-top: 5px;

  }

  .swiper-container__clz>.swiper-pagination>.swiper-pagination-bullet-active {
      opacity: 1 !important;
      background: var(--global-color) !important;
  }