::-webkit-scrollbar {
    display: none;
}

:root {
    height: 100vh;
    --global-color: #ff8205;
    --width-product-img: 245px;
}

.order-taking-container {
    display: grid;
    /* grid-template-rows: auto 70px minmax(1200px, 1490px) auto; */
    grid-template-rows: min-content min-content min-content 1fr auto;
    /* grid-template-rows: 220px 70px calc(100vh - 720px) auto; */
    grid-template-columns: auto 1fr;

    grid-template-areas:
        "header header   "
        "user user   "
        "categories headertitle"
        "categories products  "
        "footer footer   ";
    height: 100vh;
}

.order-taking-layout {
    width: 1080px;
    margin: 0 auto;
}

.order-taking-header {
    grid-area: header;
    z-index: 2;
}
.order-taking-user {
    grid-area: user;
    z-index: 2;
}

#categories {
    grid-area: categories;
    z-index: 1;
}

#products {
    grid-area: products;
    overflow-y: auto;
    z-index: 1;
}
footer {
    grid-area: footer;
    z-index: 2;
}

footer#usual-footer {
    /* height: 432px; */
    /* width: 1080px; */
    /* z-index: 1; */
    background: #ffffff 0% 0% no-repeat padding-box;
}

.separation-line {
    height: 1px;
    width: 96%;
    background-color: #cccccc;
    margin: unset 2px unset 2px;
    margin-top: unset;
    margin-bottom: unset;
    border: 0px none;
}

.category-title {
    font: italic normal 600 35px/53px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    /* text-transform: uppercase; */
    height: 70px;
}

.authenticated-user {
    height: 70px;
    background-color: white;
    z-index: 1;
}

.authenticated-user-buttons {
    height: 68px;
    width: 231px;
}

.authenticated-user-font {
    font: normal normal 25px/34px Nunito Sans;
    padding-left: 5px;
}

.main-content-visibility {
    animation: 1s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
}

.welcome-text {
    font: normal 600 40px/53px Segoe UI;
    color: #fff;
}

@keyframes fadeIn {
    99% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}

@keyframes fadeInn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.categories-swiper-width__clz {
    /* height: 45px !important;
    background: #33333326 0% 0% no-repeat padding-box;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center; */
    width: max-content !important;
    /* padding: 0 10px !important; */
}
.font-weight-bold__clz {
    font-weight: 700;
}
