.simple-keyboard {
    max-width: 872px;
}

.keyboard__clz.hg-theme-default {
    background-color: transparent !important;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 5px;
    touch-action: manipulation;
    -webkit-user-select: none;
    user-select: none;
}

.keyboard__clz.hg-theme-default .hg-button {
    -webkit-tap-highlight-color: rgba(164, 11, 11, 0);
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #b5b5b5;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 55px;
    justify-content: center;
    padding: 5px;
}

.enter-key__clz {
    background-color: rgb(50, 51, 51) !important;
    color: white;
}

.usual-keyboard-email__clz {
    width: 720px;
    height: 65px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #70707033;
    border-radius: 6px;
}

.usual-keyboard-password__clz {
    width: 800px;
    height: 60px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #70707033;
    border-radius: 6px;
}
