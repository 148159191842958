.allergens-in-one-group {
    display: flex;
    flex-flow: row wrap;
    column-gap: 50px;
    row-gap: 30px;
    width: 100%;
}
.group-allergens {
    padding-top: 15px;
    padding-bottom: 10px;
    overflow-y: scroll;
    scrollbar-width: none;
    width: 275px;
}

.title-group {
    font: normal normal bold 20px/24px Segoe UI;
    margin-bottom: 30px;
    color: rgb(15, 15, 15);
}

.two-lines-preview-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
