.menu-wrapper-nav-link__clz {
    color: white;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 0px !important;
    width: 190px;
    height: 44px;
    text-align: justify;
    white-space: nowrap !important;
    cursor: pointer !important;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: unset !important;
    border-color: unset !important;
    font-weight: bold;
    border: none !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 0px !important;
}

.menu-wrapper-nav-link__clz:hover {
    color: rgb(187, 186, 186) !important;
}
